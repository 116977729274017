.syllabus{
    /* padding: 8px; */
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.syllabus .header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 8px;
    background-color:darkcyan;
    margin-bottom: 16px;
}

.formInput{
    width: fit-content;
    min-width: 300px;
    padding: 16px;
    border: none;
    background-color: antiquewhite;
}

.formInput:focus{
    outline: none;
}

.submitButton{
    width: 70%;
    max-width: 200px;
    border: 0px;
    border-radius: 8px;
    background-color: greenyellow;
    padding: 16px;
    cursor: pointer;
}

.inputContainer{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.viewButton{
    width: 70%;
    max-width: 100px;
    border: 0px;
    border-radius: 8px;
    background-color:darkorange;
    padding: 16px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    text-align: center;
}
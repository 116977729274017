.disciplineAccordion{
    height: fit-content;
    flex-grow: 0;
    background-color: honeydew;
    border-radius: 8px;
}

.disciplineAccordion > .title{
    padding: 8px;
    cursor: pointer;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.semesterWrapper > .semesterAccordion:last-child > h3{
    border-bottom: 0px;
}

.semesterWrapper{
    max-height: 0px;
    padding: 0;
    overflow: hidden;
}

.semesterWrapper.open{
    max-height: 1000px;
}

.semesterAccordion{
    width: 100%;
}

.semesterAccordion > h3{
    background-color: cornsilk;
    padding: 16px;
    cursor: pointer;
    border-bottom: 1px solid lightgray;
}

.categoryWrapper{
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    background-color: azure;
}

.categoryWrapper.open{
    max-height: 1000px;
}

.categoryWrapper a{
    cursor: pointer;
    text-decoration: none;
    color: black;
    text-transform: capitalize;
    padding: 16px;
    border-bottom: 1px solid lightgray;

}
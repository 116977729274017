.tableOfContentsWrapper{
    padding: 16px;
    border: 1px solid lightgray;
}

.tableOfContentsWrapper ul li{
    list-style: none;
    margin: 8px 0px;
    width: fit-content;
}

.tableOfContentsWrapper ul li:hover {
    text-decoration: underline;
    cursor: pointer;
}
.notesUG{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.notesLink{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.notesUG .header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 8px;
    background-color:darkcyan;
    margin-bottom: 16px;
}
.homepage{
    height: 89vh;
}

.homepage .sections{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 16px;
}

.navbar{
    background-color: black;
    padding: 16px;
}

.navbar .title{
    color: white;
}

.sectionsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.section{
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cadetblue;
    border-radius: 8px;
}
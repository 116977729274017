.course{
    padding: 32px 8px;
    background-color: cadetblue;
}

.disciplinesContainer{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    grid-gap: 16px;
    padding: 8px;
    background-color: khaki;
}
.subjects{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 16px;
    padding: 16px;
}

.subjectCard{
    height: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: center;
    align-items: center;
    background-color: bisque;
    text-align: center;
    cursor: pointer;
}

.subjectCard .categories{
    display: flex;
    column-gap: 8px;
}

.subjectCard .categories a{
    background-color: beige;
    padding: 8px;
}

.subjectCard .categories a:hover{
    background-color: aqua;
}
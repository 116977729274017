*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body{
  min-height: 100vh;
}

#root, .App{
  min-height: 100%;
}

body{
  font-size: 16px;
  font-family: sans-serif;
}

a{
  text-decoration: none;
  color: black;
}

button, .linkButton, a.btn-secondary {
  width: fit-content;
  padding: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

input, select, textarea{
  padding: 16px;
  border: 1px solid lightgray;
  width: 300px;
  font-size: 16px;
}

input:focus, textarea:focus {
  outline: none;
}

.btn-primary{
  background-color: darkcyan!important;
}

.btn-secondary{
  background-color: orange!important;
}

.btn-red{
  background-color: red!important;
}

.form{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}

.element{
  padding: 0px 32px;
}

.link{
  color: dodgerblue;
}

.truncate-multiple {
  width: 300px; /* Set a specific width */
  display: -webkit-box; /* Use a flexible box model for block-level elements */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  -webkit-line-clamp: 3; /* Limit text to 3 lines */
  overflow: hidden; /* Hide content that overflows the container */
}

.modal-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.border-outline {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 16px
}
.courses{
    display: grid;
    flex-direction: column;
    gap: 32px;
}

.modalBox{
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 400;
    background-color: 'background.paper';
    box-shadow: 24;
    padding: 4;
    display: 'flex';
    flex-direction: 'column';
    gap: '16px';
}